<template>
  <el-row class="table">
    <span class="title">课消明细</span>
    <el-row class="row-bg">
      <el-date-picker
        class="tb-picker"
        v-model="time"
        type="datetimerange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00']"
      ></el-date-picker>
      <el-select v-model="select" placeholder="请选择分类（默认全选）" class="tb-select">
        <el-option v-for="item in typeArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    </el-row>
    <el-table :data="dataList" class="tb-list" border fit highlight-current-row>
      <el-table-column label="类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.flag == 0" :style="'color'+':'+getStateColor(scope.row.flag)">消费</span>
          <span v-if="scope.row.flag == 1" :style="'color'+':'+getStateColor(scope.row.flag)">充值</span>
          <span v-if="scope.row.flag == -1" :style="'color'+':'+getStateColor(scope.row.flag)">退款</span>
        </template>
      </el-table-column>
      <el-table-column label="课时数" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="单价" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.unitPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总价" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.courseMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收据号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.receiptno }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消课班级" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.className }}</span>
        </template>
      </el-table-column>
      <el-table-column label="消课排课" align="center">
        <template slot-scope="scope">
          <span
            class="tb-span"
          >{{ scope.row.start_time | formatDateStart }}{{ scope.row.end_time | formatDateEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createtime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="danger" @click="delHour(scope.row)" size="mini">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="this.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getBySudentCourse, delLessonHour } from '@/api/statistics'

export default {
  data () {
    return {
      time: null,
      courseid: '',
      studentid: '',
      dataList: [],
      select: '',
      total: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      typeArray: [
        {
          id: '0',
          name: '消费'
        },
        {
          id: '1',
          name: '充值'
        },
        {
          id: '-1',
          name: '退款'
        }
      ]
    }
  },
  created () {
    this.courseid = this.$route.query.courseID
    this.studentid = this.$route.query.studentID
    this.queryDataList()
  },
  methods: {
    delHour (row) {
      this.$confirm('是否删除当前明细', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delLessonHour({
            id: row.id
          })
          window.$msg('删除成功')
          this.queryDataList()
        })
    },
    async queryDataList () {
      const res = await getBySudentCourse({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        course_id: this.courseid,
        student_id: this.studentid,
        startTime: Boolean(this.time) ? this.time[0] : '',
        endTime: Boolean(this.time) ? this.time[1] : '',
        flag: this.select
      })
      this.dataList = res.body.list
      this.total = res.body.total
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.queryDataList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.queryDataList()
    },
    getStateColor (state) {
      switch (state) {
        case 1:
          return '#008000'
        case -1:
          return '#FF0000'
        case 0:
          return '#f5c319'
      }
    },
    search () {
      this.pagination.currentPage = 1
      this.queryDataList()
    },

    reset () {
      this.select = ''
      this.time = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
